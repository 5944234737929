import firebaseAdmin from "../plugins/firebase-admin";
import firebase from "../plugins/firebase-app";

export default class UserService {
  private db: firebaseAdmin.firestore.Firestore | firebase.firestore.Firestore;

  constructor(db: firebaseAdmin.firestore.Firestore | firebase.firestore.Firestore) {
    this.db = db;
  }

  public async getUserById(userId: string) {
    return (await this.db.collection("Users").doc(userId).get()).data();
  }
}
