
import Vue from "vue";
import Drawer from "@/components/Drawer.vue";
import store from "@/store";

export default Vue.extend({
  name: "App",
  components: {
    Drawer,
  },
  computed: {
    loading() {
      return store.state.loading;
    },
    error() {
      return store.state.error;
    },
  },
});
