import Vue from "vue";
import VueSwal from "vue-swal";

declare module "vue/types/vue" {
  interface Vue {
    $swal: typeof VueSwal;
  }
}

Vue.use(VueSwal);
