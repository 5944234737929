export default [
  {
    path: "/",
    name: "Dashboard",
    view: "Dashboard",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    view: "Login",
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/user-accounts",
    name: "User Accounts",
    view: "UserProfile",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/file",
    name: "file",
    view: "File",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/migrate",
    name: "Migrate",
    view: "Migrate",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contacts",
    name: "Contacts",
    view: "Contacts",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contactGroups",
    name: "Contact Groups",
    view: "ContactGroups",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/audience",
    name: "Audience",
    view: "Audience",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/session/:id",
    name: "Session",
    view: "Session",
    meta: {
      requiresAuth: true,
    },
    props: true,
  },
  {
    path: "/events",
    name: "Events",
    view: "Events",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/logout",
    name: "Logout",
    view: "Logout",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/forms",
    name: "Forms",
    view: "Forms",
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings",
    view: "Settings",
    name: "Settings",
    meta: {
      requiresAuth: true,
    },
  },
  { path: "*", redirect: "/" },
];
