import { LayoutSetting, Theme } from "../models";

export const defaultTheme: Theme = {
  id: null as string | null,
  name: "Default",
  companyId: null as string | null,
  backgroundEndColour: "#FFFFFFFF",
  backgroundStartColour: "#FFFFFFFF",
  descriptionTextColour: "#DDDDDDFF",
  elementBackgroundColour: "#FFFFFFFF",
  elementBorderColour: "#DDDDDDFF",
  elementPlaceholderColour: "#DDDDDDFF",
  elementTextColour: "#000000FF",
  fontFamily: "arial",
  fontSize: null,
  navigationButtonBackgroundColour: "#DDDDDDFF",
  navigationButtonTextColour: "#000000FF",
  optionsButtonBackgroundColour: "#DDDDDDFF",
  optionsButtonTextColour: "#000000FF",
  titleTextColour: "#000000FF",
};

export const defaultLayoutSettings: LayoutSetting = {
  alternating: false,
  showDivider: false,
  wholeAreaClickable: false,
  textAlignment: "left",
  columnsPerRow: 1,
  layout: "Default",
  enabled: true,
};
