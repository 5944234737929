import Vue from "vue";
import Vuex from "vuex";
import { Company, Setting } from "../../../shared/models";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userDetail: {
      loggedIn: false,
      userData: null as any,
    },
    company: null as Company | null,
    loading: false,
    error: null as string | null,
    setting: null as Setting | null,
  },
  getters: {
    userId(state) {
      return state.userDetail.userData ? state.userDetail.userData.uid : null;
    },
    companyId(state) {
      return state.userDetail.userData ? state.userDetail.userData.companyId : null;
    },
    user(state) {
      return state.userDetail.userData;
    },
    company(state) {
      return state.company;
    },
    setting(state) {
      return state.setting;
    },
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.userDetail.loggedIn = value;
    },
    SET_USER(state, data) {
      state.userDetail.userData = data;
    },
    SET_COMPANY(state, data) {
      state.company = data;
    },
    SET_LOADING(state, data) {
      state.loading = data;
    },
    SET_ERROR(state, data) {
      state.error = data;
    },
    SET_Setting(state, data) {
      state.setting = data;
    },
  },
  actions: {
    setUser({ commit }, user) {
      commit("SET_LOGGED_IN", user !== null);
      commit("SET_USER", user || null);
    },
    setCompany({ commit }, company) {
      commit("SET_COMPANY", company || null);
    },
    setLoading({ commit }, data) {
      commit("SET_LOADING", data || false);
    },
    setError({ commit }, data) {
      commit("SET_ERROR", data || null);
    },
    setSetting({ commit }, setting) {
      commit("SET_Setting", setting || null);
    },
  },
  modules: {},
});
