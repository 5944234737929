import firebase, { auth, functions, db } from "../../../shared/plugins/firebase-app";
import store from "@/store";
import companyService from "./companyService";
import BaseUserService from "../../../shared/services/UserService";
import settingService from "./settingService";

class UserService extends BaseUserService {
  constructor() {
    super(db);
  }

  public userList(companyId) {
    return db.collection("Users").where("companyId", "==", companyId);
  }

  public async signOut() {
    await auth.signOut();
  }

  public currentUser() {
    return auth.currentUser;
  }

  public async getCurrentUserAsync() {
    return new Promise((resolve, reject) => {
      auth.onAuthStateChanged(function (user) {
        if (user) {
          resolve(user);
        } else {
          resolve(null);
        }
      });
    });
  }

  public async singInWithCredential(email, password) {
    return await auth.signInWithEmailAndPassword(email, password);
  }

  public async resetPasswordUser(email) {
    return await auth.sendPasswordResetEmail(email);
  }

  public async changeCurrentUserPassword(currentPassword, newPassword) {
    const user = this.currentUser();
    const credential = firebase.auth.EmailAuthProvider.credential(user?.email || "", currentPassword);
    if (user) {
      await user.reauthenticateWithCredential(credential);
      user?.updatePassword(newPassword);
    }
  }

  public updateUser(user) {
    if (!user.id) {
      return functions.httpsCallable("registerUser");
    } else {
      return functions.httpsCallable("updateUser");
    }
  }

  public async updateUserInfo(user) {
    await db.collection("Users").doc(user.id).set(user, { merge: true });
  }

  public getCurrentUserInfo() {
    const user = this.currentUser();
    if (user) {
      return db.collection("Users").doc(user.uid);
    }
    return null;
  }

  public getUserInfo(uid) {
    return db.collection("Users").doc(uid);
  }

  public async getCurrentUserData() {
    const user = this.currentUser();
    if (user) {
      return db.collection("Users").doc(user.uid).get();
    }
    return null;
  }

  public async getCurrentUserDataAsync(onSuccess, onError) {
    try {
      const user: any = await this.getCurrentUserAsync();
      if (user) {
        const userData = await db.collection("Users").doc(user.uid).get();
        if (onSuccess) {
          onSuccess(userData.data());
        }
      } else {
        if (onSuccess) {
          onSuccess(null);
        }
      }
    } catch (error: any) {
      if (onError) {
        onError(error);
      }
    }
  }

  public async updateCurrentUserInfo(data) {
    const user = this.currentUser();
    if (user) {
      return db
        .collection("Users")
        .doc(user.uid)
        .set({ id: user.uid, email: user.email, ...data }, { merge: true });
    }
  }

  public async storeUserDataAndCompany(firebaseUser) {
    if (firebaseUser) {
      const userDoc = await this.getUserInfo(firebaseUser.uid).get();
      const userData = userDoc.data();
      if (userData) {
        await store.dispatch("setUser", {
          ...{
            email: firebaseUser.email,
            uid: firebaseUser.uid,
          },
          ...userData,
        });
        if (!userData.companyId) {
          throw new Error("User company does not exist.");
        }
        const companyDoc = await companyService.getCompany(userData.companyId);
        const setting = await settingService.getSetting();
        const company = companyDoc?.data();
        if (company) {
          await store.dispatch("setCompany", company);
        } else {
          throw new Error("User company does not exist.");
        }
        if (setting) {
          await store.dispatch("setSetting", setting);
        } else {
          throw new Error("Setting does not exist.");
        }
      } else {
        throw new Error("User data does not exist.");
      }
    }
  }
}

const userService = new UserService();
export default userService;
