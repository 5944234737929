import Vue from "vue";
import moment from "moment";
import { CommunicationScheduleStatus } from "../../../shared/enums/CommunicationScheduleStatus";

Vue.filter("longDateTime", (value) => {
  return value ? moment(value).format("LLLL") : "";
});

Vue.filter("CommunicationScheduleStatus", (value) => {
  return value ? CommunicationScheduleStatus[value] : "";
});

Vue.filter("formatDateTime", (value, format) => {
  return value ? moment(value).format(format) : "";
});

Vue.filter("firebaseTimeToDate", (value) => {
  return value ? (value.toDate ? value.toDate() : new Date(value.seconds * 1000)) : "";
});

Vue.filter("separateWords", (value) => {
  return !value
    ? ""
    : value
        // Look for long acronyms and filter out the last letter
        .replace(/([A-Z]+)([A-Z][a-z])/g, " $1 $2")
        // Look for lower-case letters followed by upper-case letters
        .replace(/([a-z\d])([A-Z])/g, "$1 $2")
        // Look for lower-case letters followed by numbers
        .replace(/([a-zA-Z])(\d)/g, "$1 $2")
        .replace(/^./, function (str) {
          return str.toUpperCase();
        })
        // Remove any white space left around the word
        .trim();
});
