import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import "@/plugins/vue-filepond";
import "@/plugins/vue-draggable";
import "@/plugins/vuelidate";
import "@/plugins/vue-toast-notification";
import "@/plugins/vue-swal";
import "@/plugins/vue-organization-chart";
import "@/plugins/verte";
import "@/extensions/notification";
import "@/extensions/filters";
import "@/extensions/prototype";
import "../../shared/plugins/firebase-app";
import "@/assets/main.scss";

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
} as any).$mount("#app");
