
import Vue from "vue";
import store from "@/store";

export default Vue.extend({
  data() {
    return {
      drawer: true,
      items: [
        {
          to: "/user-accounts",
          icon: "mdi-account",
          title: "User Accounts",
        },
        {
          to: "/contacts",
          icon: "mdi-contacts",
          title: "Contacts",
        },
        {
          to: "/contactGroups",
          icon: "mdi-account-multiple-outline",
          title: "Contact Groups",
        },
        {
          to: "/events",
          icon: "mdi-calendar",
          title: "Events",
        },
        {
          to: "/forms",
          icon: "mdi-watermark",
          title: "Forms",
        },
        {
          to: "/settings",
          icon: "mdi-cog",
          title: "Settings",
        },
        {
          to: "/audience",
          icon: "mdi-chart-donut-variant",
          title: "Audience",
        },
        {
          to: "/Logout",
          icon: "mdi-logout",
          title: "Logout",
        },
      ],
      mini: true,
    };
  },
  computed: {
    userFullName() {
      return `${store.getters.user?.firstName || ""} ${store.getters.user?.lastName || ""}`.trim();
    },
  },
});
