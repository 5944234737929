import Vue from "vue";
import Router from "vue-router";
import paths from "./paths";
import { auth } from "../../../shared/plugins/firebase-app";
import store from "@/store";
import userService from "@/services/userService";
import versionService from "@/services/versionService";
import { AppVersion } from "../../../shared/constants";

function route(path, view, name, meta, props) {
  return {
    name: name || view,
    path,
    component: (resolve) => import(`@/views/${view}.vue`).then(resolve),
    meta,
    props,
  };
}

Vue.use(Router);

// Create a new router
const router = new Router({
  mode: "history",
  routes: paths.map((path) => route(path.path, path.view, path.name, path.meta, path.props || false)),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  try {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    auth.onAuthStateChanged(async (firebaseUser) => {
      if (
        requiresAuth &&
        firebaseUser &&
        !(store.state.userDetail && store.state.userDetail.userData && store.state.userDetail.userData.id === firebaseUser.uid)
      ) {
        try {
          await store.dispatch("setLoading", true);
          // eslint-disable-next-line no-console
          console.log("storeUserDataAndCompany", firebaseUser);
          await userService.storeUserDataAndCompany(firebaseUser);
        } catch (error: any) {
          await store.dispatch("setError", error.message || "Error!");
        } finally {
          await store.dispatch("setLoading", false);
        }
      }
      const returnUrl = (to.path || "").toLowerCase() == "/logout" ? "" : to.path;
      if (requiresAuth && !firebaseUser) {
        next(`/Login${returnUrl ? "?returnUrl=" + encodeURIComponent(returnUrl) : ""}`);
      } else {
        if (
          firebaseUser &&
          (await versionService.getDatabaseVersion()) !== AppVersion &&
          to.path !== "/Migrate" &&
          to.path !== "/Logout" &&
          to.path !== "/Login"
        ) {
          next(`/Migrate${returnUrl ? "?returnUrl=" + encodeURIComponent(returnUrl) : ""}`);
        } else {
          next();
        }
      }
    });
  } catch (error: any) {
    await store.dispatch("setError", error.message || "Error!");
  }
});

export default router;
