import firebase from "../plugins/firebase-app";
import firebaseAdmin from "../plugins/firebase-admin";
import { Setting } from "../models";

export default class SettingService {
  private db: firebaseAdmin.firestore.Firestore | firebase.firestore.Firestore;

  constructor(db: firebaseAdmin.firestore.Firestore | firebase.firestore.Firestore) {
    this.db = db;
  }

  public static DefaultSettingId = "uXLSA8cqWmNq2iXoO69O";
  public static DefaultClientSecret = "DqPXVrtnS86sXgDK6CoD";

  public async getSetting(): Promise<Setting> {
    const x = await this.db.collection("Settings").doc(SettingService.DefaultSettingId).get();
    if (!x.exists) {
      return {
        id: SettingService.DefaultSettingId,
        jwtClientId: "",
        jwtClientSecret: SettingService.DefaultClientSecret,
      };
    }
    return x.data() as Setting;
  }

  public async saveSetting(data: Setting) {
    return await this.db.collection("Settings").doc(SettingService.DefaultSettingId).set(data);
  }
}
