(String.prototype as any).ensureEndsWith = function (value: string) {
  const target: string = (this || "").toString();
  if (!target || target.endsWith(value)) {
    return target;
  }
  return target + value;
};

(String.prototype as any).ensureNotEndsWith = function (value: string) {
  const target: string = (this || "").toString();
  if (!target || !target.endsWith(value)) {
    return target;
  }
  return target.substring(0, target.length - value.length);
};

(String.prototype as any).ensureNotStartsWith = function (value: string) {
  const target: string = (this || "").toString();
  if (!target || !target.startsWith(value)) {
    return target;
  }
  return target.substring(value.length);
};

export {};

declare global {
  export interface String {
    ensureEndsWith: (value: string) => string;
    ensureNotEndsWith: (value: string) => string;
    ensureNotStartsWith: (value: string) => string;
  }
}
