import firebase from "../plugins/firebase-app";
import firebaseAdmin from "../plugins/firebase-admin";
import { Company } from "../models";

export default class CompanyService {
  private db: firebaseAdmin.firestore.Firestore | firebase.firestore.Firestore;

  constructor(db: firebaseAdmin.firestore.Firestore | firebase.firestore.Firestore) {
    this.db = db;
  }

  public async getCompany(companyId: string) {
    if (!companyId) {
      return null;
    }
    return await this.db.collection("Companies").doc(companyId).get();
  }

  public async saveCompany(companyId: string, data: Partial<Company>) {
    if (!companyId) {
      return null;
    }
    return await this.db.collection("Companies").doc(companyId).update(data);
  }
}
