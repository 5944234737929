import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/functions";
import "firebase/compat/firestore";
import { region } from "../constants";

const config =
  process.env.NODE_ENV === "production"
    ? {
        apiKey: "AIzaSyBuUZ6zbUcw6Dk_G1HcwKfSNT3TfBPs3lE",
        authDomain: "sight-forms.firebaseapp.com",
        databaseURL: "sight-forms.firebaseio.com",
        projectId: "sight-forms",
        storageBucket: "sight-forms.appspot.com",
        messagingSenderId: "534235484289",
        appId: "1:534235484289:web:551a965780896f3380bd80",
        measurementId: "G-WFYVNWYBPK",
      }
    : {
        apiKey: "AIzaSyBuUZ6zbUcw6Dk_G1HcwKfSNT3TfBPs3lE",
        authDomain: "sight-forms.firebaseapp.com",
        databaseURL: "https://sight-forms.firebaseio.com",
        projectId: "sight-forms",
        storageBucket: "sight-forms.appspot.com",
        messagingSenderId: "534235484289",
        appId: "1:534235484289:web:551a965780896f3380bd80",
        measurementId: "G-WFYVNWYBPK",
      };

const fireApp = firebase.apps.length ? firebase.app() : firebase.initializeApp(config);
firebase.firestore.setLogLevel("silent");

export const db = fireApp.firestore();
if (!firebase.apps.length) {
  db.settings({ ignoreUndefinedProperties: true });
}
if (process.env.Emulator) {
  db.useEmulator("localhost", 8082);
}
export const storage = fireApp.storage();
if (process.env.Emulator) {
  storage.useEmulator("localhost", 9199);
}
export const functions = fireApp.functions(region);
if (process.env.Emulator) {
  functions.useEmulator("localhost", 5001);
}
export const auth = fireApp.auth();
if (process.env.Emulator) {
  auth.useEmulator("http://localhost:9099");
}

export default firebase;
