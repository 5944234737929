import Vue from "vue";

const notification = {
  showError(message: string) {
    Vue.$toast.open({ message, type: "error", position: "top", duration: 5000, pauseOnHover: true, dismissible: true, queue: false });
  },

  showSuccess(message: string) {
    Vue.$toast.open({ message, type: "success", position: "top", duration: 5000, pauseOnHover: true, dismissible: true, queue: false });
  },
};

Vue.prototype.$notification = notification;

declare module "vue/types/vue" {
  interface Vue {
    $notification: typeof notification;
  }
}
