import firebase from "../plugins/firebase-app";
import firebaseAdmin from "../plugins/firebase-admin";
export default class VersionService {
  private db: firebaseAdmin.firestore.Firestore | firebase.firestore.Firestore;

  constructor(db: firebaseAdmin.firestore.Firestore | firebase.firestore.Firestore) {
    this.db = db;
  }

  public static cachedDatabaseVersionValue = 0;

  public async getDatabaseVersion(): Promise<number> {
    if (VersionService.cachedDatabaseVersionValue) {
      return VersionService.cachedDatabaseVersionValue;
    }
    const doc = await this.db.collection("Info").doc("app-info").get();
    if (!doc.exists) {
      return 0;
    }

    VersionService.cachedDatabaseVersionValue = doc.data()?.DatabaseVersion || 0;
    return VersionService.cachedDatabaseVersionValue;
  }

  public async setDatabaseVersion(version: number): Promise<void> {
    await this.db.collection("Info").doc("app-info").set({ DatabaseVersion: version });
    VersionService.cachedDatabaseVersionValue = version;
  }
}
